<template>
  <div class="assign-project-lawyer fd-validation" :class="{ error: hasError }">
    <fd-form-section title="Assign Lawyers">
      <banner
        v-if="hasError"
        class="col-12 bg-danger fg-white py-2"
        :dismissable="false"
      >
        Please assign at least 1 lawyer to this project.
      </banner>

      <!-- To Assign List -->
      <LawyersToAssign
        class="col-12 md-col-6 px-3"
        :lawyers="lawyersAvailable"
        :isLoading="isLawyerToAssignLoading"
        @search="searchLawyerToAssign"
        @assign="assignLawyer"
        @infinite-scroll="loadNextLawyersToAssign"
      ></LawyersToAssign>

      <!-- Assigned List -->
      <LawyersAssigned
        class="col-12 md-col-6 px-3"
        :lawyers="lawyersAssigned"
        :isLoading="isLawyersAssignedLoading"
        @remove="removeAssignedLawyer"
        @infinite-scroll="loadNextLawyersAssigned"
      >
      </LawyersAssigned>
    </fd-form-section>
  </div>
</template>

<script>
import useValidator from "@/modules/General/composables/useValidator";
import useAssignToProject from "@/modules/Project/composables/useAssignToProject";

export default {
  setup(props, context) {
    const { validate, hasError, errorMessage } = useValidator(props);
    const {
      isToAssignLoading: isLawyerToAssignLoading,
      toAssign: lawyersToAssign,
      toAssignPagination: lawyersToAssignPagination,

      isAssignedLoading: isLawyersAssignedLoading,
      assigned: lawyersAssigned,
      assignedPagination: lawyersAssignedPagination,

      availableToAssign: lawyersAvailable,

      searchToAssign: searchLawyerToAssign,
      loadNextToAssign: loadNextLawyersToAssign,
      loadNextAssigned: loadNextLawyersAssigned,
      assignUser: assignLawyer,
      removeAssigned: removeAssignedLawyer,
      reloadAssigned: reloadAssignedLawyers
    } = useAssignToProject("lawyer", { props, context });

    return {
      validate,
      hasError,
      errorMessage,
      // To Assign
      isLawyerToAssignLoading,
      lawyersToAssign,
      lawyersToAssignPagination,
      // Assigned
      isLawyersAssignedLoading,
      lawyersAssigned,
      lawyersAssignedPagination,

      lawyersAvailable,
      // Methods
      searchLawyerToAssign,
      loadNextLawyersToAssign,
      assignLawyer,
      removeAssignedLawyer,
      loadNextLawyersAssigned,
      reloadAssignedLawyers
    };
  },
  components: {
    Banner: () => import("@/components/GlobalComponents/Banner"),
    LawyersToAssign: () => import("./LawyersToAssign"),
    LawyersAssigned: () => import("./LawyersAssigned")
  },
  mixins: [],
  props: {
    value: {
      type: Array,
      default: () => []
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    projectId: {
      type: [Number, String]
    },
    type: {
      type: String,
      validator: (val) => ["agency", "developer"].includes(val)
    },
    mapValue: {
      type: Function,
      default: (val) => val
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
